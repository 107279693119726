<template>
  <div>
    <div class="flex justify-between items-center mb-base">
      <h2>{{$t('UROs')}}</h2>
      <vs-button v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || $store.state.AppActiveUser.userRole === 'fc-receiving-agent' || $store.state.AppActiveUser.userRole === 'fc-inbounding-agent'" @click="openUROModal()" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadUROs"></head-component>
    <shipblu-table
     :sst="true"
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="UROs"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Request ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Stock Request')}}</shipblu-th>
        <shipblu-th>{{$t('Number of SKUs')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{name: `${$store.state.AppActiveUser.userRole}-unidentified-stock-request-view`, params: {stockID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].requestID">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant ? data[indextr].merchant.name : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].stock">
            {{ data[indextr].stock_request ? data[indextr].stock_request.id : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfSKU">
            {{ data[indextr].unidentified_products.length }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-unidentified-stock v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || $store.state.AppActiveUser.userRole === 'fc-receiving-agent' || $store.state.AppActiveUser.userRole === 'fc-inbounding-agent'" :UROModal="UROModal" @UROModal="UROModal = $event"
      @loadUROs="loadUROs" />
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import AddUnidentifiedStock from '../../fcOperationsSupervisor/components/AddUnidentifiedStock.vue'
import HeadComponent from '../components/Header.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      UROs: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'on hold',
          value: 'on_hold'
        },
        {
          name: 'resolved',
          value: 'resolved'
        },
        {
          name: 'failed',
          value: 'failed'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      totalRows: 0,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      selected: [],
      UROModal: false,
      filters: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadUROs()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadUROs()
      }
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadUROs()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadUROs()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (stock) {
      return common.getOrderStatusColor(this.getStatusLabel(stock))
    },
    getStatusLabel (stock) {
      return common.getStatusLabel(stock, this.$store.state.AppActiveUser.userRole)
    },
    openUROModal () {
      this.UROModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadUROsSearch)
    },
    loadUROsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadUROs()
    },
    loadUROs () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/unidentified-stock-requests/${query}`, 'get', null, true,
        (response) => {
          this.UROs = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false    
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddUnidentifiedStock,
    HeadComponent,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadUROs()
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
table.shipblu-table td:first-child{
  border-left: 0px solid !important;
}
</style>
